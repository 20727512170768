import React, {useState} from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './letsgetpersonal.css'

const Letsgetpersonal = (props) => {
  const [name1, setName1] = useState();
  const [name2, setName2] = useState();

  return (
    <div className="letsgetpersonal-container">
      <Helmet>
        <title>TUNNEL UI</title>
      </Helmet>
      <div className="letsgetpersonal-letsgetpersonal">
        <div className="letsgetpersonal-textblock">
          <span className="letsgetpersonal-text H2">
            <span>Welcome to Tunnel.</span>
          </span>
          <span className="letsgetpersonal-text2 H2">
            <span>Let’s get personal.</span>
          </span>
        </div>
        <div className="letsgetpersonal-fields">
          <input
            type="text"
            placeholder="Spencer"
            className="letsgetpersonal-nameinput"
            onInput={(e) => {
              setName1(e.target.value);
            }}
          />
          <input
            type="text"
            placeholder="Wu"
            className="letsgetpersonal-nicknameinput"
            onInput={(e) => {
              setName2(e.target.value);
            }}
          />
        </div>
        <Link
          to="/entertheverificationcodeyoureceive"
          className={`letsgetpersonal-buttonsbutton ${name1 && name2 ? '' : 'disabled'}`}
        >
          <span className="letsgetpersonal-text4 BodyRegular">
            <span>Continue</span>
          </span>
        </Link>
      </div>
    </div>
  )
}

export default Letsgetpersonal
