import React from 'react'
import {BrowserRouter as Router} from "react-router-dom";

import { Helmet } from 'react-helmet'

import './creatingyouraccount.css'

const Creatingyouraccount = ({history}) => {
    const timeout = setTimeout(() => {
        history.push('/homescreen')
        clearTimeout(timeout)
    }, 3_000)

  return (
    <div className="creatingyouraccount-container">
      <Helmet>
        <title>TUNNEL UI</title>
      </Helmet>
      <div className="creatingyouraccount-creatingyouraccount">
        <div className="creatingyouraccount-block">
          <span className="creatingyouraccount-text BodyRegular">
            <span>Creating your account...</span>
          </span>
          <img
            src="/external/logosvg9022-cgl.svg"
            alt="logosvg9022"
            className="creatingyouraccount-logosvg"
          />
        </div>
      </div>
    </div>
  )
}

export default Creatingyouraccount
