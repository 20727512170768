import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './welcome.css'

const Welcome = (props) => {
  return (
    <div className="welcome-container">
      <Helmet>
        <title>TUNNEL UI</title>
      </Helmet>
      <div className="welcome-welcome">
        <img
          src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/d5c9830b-4cfc-49cf-811a-6ebe0f8c0205/a2e58ee1-3021-48f3-ab4b-b8c6f63a9c98?org_if_sml=1201215"
          alt="Bg19022"
          className="welcome-bg1"
        />
        <button className="welcome-buttons">
          <button className="welcome-buttonsbutton">
            <span className="welcome-text BodyRegular">SignIn</span>
          </button>
          <Link to="/createyouraccount" className="welcome-buttons2button">
            <img
              src
              alt="arrowcircleupI901"
              className="welcome-arrowcircleup"
            />
            <span className="welcome-text1 BodyRegular">Sign Up</span>
            <img
              src="/external/arrowcircleupsvgi901-zq1v.svg"
              alt="arrowcircleupsvgI901"
              className="welcome-arrowcircleupsvg"
            />
          </Link>
        </button>
        <div className="welcome-textblock">
          <span className="welcome-text2 H1">
            <span>Welcome to Tunnel.</span>
          </span>
          <span className="welcome-text4 H2">
            <span>
              <span>Luxury experiences,</span>
              <br></br>
              <span>accessible.</span>
            </span>
          </span>
        </div>
      </div>
    </div>
  )
}

export default Welcome
