import React, {useState} from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './entertheverificationcodeyoureceive.css'

const Entertheverificationcodeyoureceive = (props) => {
  const [phone, setPhone] = useState();
  const [code, setCode] = useState();

  return (
    <div className="entertheverificationcodeyoureceive-container">
      <Helmet>
        <title>TUNNEL UI</title>
      </Helmet>
      <div className="entertheverificationcodeyoureceive-entertheverificationcodeyoureceive">
        <div className="entertheverificationcodeyoureceive-textblock">
          <span className="entertheverificationcodeyoureceive-text H2">
            <span>Welcome to Tunnel.</span>
          </span>
          <span className="entertheverificationcodeyoureceive-text02 H2">
            <span>Enter the verification code you receive.</span>
          </span>
        </div>
        <div className="entertheverificationcodeyoureceive-fields">
          <div className={`entertheverificationcodeyoureceive-phoneinput ${phone ? 'active' : ''}`}>
            <span className="entertheverificationcodeyoureceive-text04 BodyLight">
              <span>+1</span>
            </span>
            <img
              src="/external/expandmoresvg9022-qw5q.svg"
              alt="expandmoresvg9022"
              className="entertheverificationcodeyoureceive-expandmoresvg"
            />
            <input type={'text'} placeholder={'4156020788'} className={'enter-phone'} onFocus={(e) => {
              setPhone(true)
            }} onBlur={(e) => {
              setPhone(false)
            }} />
          </div>
          <input
            type="text"
            placeholder="509432"
            className="entertheverificationcodeyoureceive-codeinput"
            onInput={(e) => {
              setCode(e.target.value);
            }}
          />
        </div>
        <Link
          to="/creatingyouraccount"
          className={`entertheverificationcodeyoureceive-buttonsbutton ${code ? '' : 'disabled'}`}
        >
          <span className="entertheverificationcodeyoureceive-text08 BodyRegular">
            <span>Create Account</span>
          </span>
        </Link>
        <span className="entertheverificationcodeyoureceive-text10 Small">
          <span>
            By clicking Create Account, you agree to our Terms and Private
            Policy and consent to receive text messages and notifications from
            us related to your flight. Message and data rates apply. Text STOP
            to cancel.
          </span>
        </span>
      </div>
    </div>
  )
}

export default Entertheverificationcodeyoureceive
