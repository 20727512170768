import React, {useState} from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './createyouraccount.css'

const Createyouraccount = (props) => {
  const [password, setPassword] = useState();
  const [login, setLogin] = useState();

  return (
    <div className="createyouraccount-container">
      <Helmet>
        <title>TUNNEL UI</title>
      </Helmet>
      <div className="createyouraccount-createyouraccount">
        <div className="createyouraccount-textblock">
          <span className="createyouraccount-text H2">
            <span>Welcome to Tunnel.</span>
          </span>
          <span className="createyouraccount-text02 H2">
            <span>Create your account.</span>
          </span>
        </div>
        <div className="createyouraccount-fields">
          <input
            type="text"
            placeholder="spencerewu@gmail.com"
            className="createyouraccount-emailinput"
            onInput={(e) => {
              setLogin(e.target.value);
            }}
          />
          <input
            type="password"
            placeholder="··············"
            className="createyouraccount-passwordinput"
            onInput={(e) => {
              setPassword(e.target.value);
            }}
          />
        </div>
        <div className="createyouraccount-frame2796">
          <div className="createyouraccount-frame2795">
            <div className="createyouraccount-line">
              <img
                src="/external/checkcirclesvg9022-3pon.svg"
                alt="checkcirclesvg9022"
                className="createyouraccount-checkcirclesvg"
              />
              <span className="createyouraccount-text04 Small">
                <span>8 characters</span>
              </span>
            </div>
            <div className="createyouraccount-line1">
              <img
                src="/external/checkcirclesvg9022-0ve.svg"
                alt="checkcirclesvg9022"
                className="createyouraccount-checkcirclesvg1"
              />
              <span className="createyouraccount-text06 Small">
                <span>1 capital letter</span>
              </span>
            </div>
          </div>
          <div className="createyouraccount-frame2794">
            <div className="createyouraccount-line2">
              <img
                src="/external/checkcirclesvg9022-3eym.svg"
                alt="checkcirclesvg9022"
                className="createyouraccount-checkcirclesvg2"
              />
              <span className="createyouraccount-text08 Small">
                <span>Any number</span>
              </span>
            </div>
            <div className="createyouraccount-line3">
              <img
                src="/external/checkcirclesvg9022-1lep.svg"
                alt="checkcirclesvg9022"
                className="createyouraccount-checkcirclesvg3"
              />
              <span className="createyouraccount-text10 Small">
                <span>Any special characters</span>
              </span>
            </div>
          </div>
        </div>
        <Link to="/letsgetpersonal" className={`createyouraccount-buttonsbutton ${login && password ? '' : 'disabled'}`}>
          <span className="createyouraccount-text12 BodyRegular">
            <span>Continue</span>
          </span>
        </Link>
      </div>
    </div>
  )
}

export default Createyouraccount
