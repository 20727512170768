import React from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

import './style.css'
import Createyouraccount from './views/createyouraccount'
import Letsgetpersonal from './views/letsgetpersonal'
import Entertheverificationcodeyoureceive from './views/entertheverificationcodeyoureceive'
import Creatingyouraccount from './views/creatingyouraccount'
import Welcome from './views/welcome'
import Homescreen from './views/homescreen'
import NotFound from './views/not-found'

const App = () => {
  return (
    <Router>
      <Switch>
        <Route component={Createyouraccount} exact path="/createyouraccount" />
        <Route component={Letsgetpersonal} exact path="/letsgetpersonal" />
        <Route
          component={Entertheverificationcodeyoureceive}
          exact
          path="/entertheverificationcodeyoureceive"
        />
        <Route
          component={Creatingyouraccount}
          exact
          path="/creatingyouraccount"
        />
        <Route component={Welcome} exact path="/" />
        <Route component={Homescreen} exact path="/homescreen" />
        <Route component={NotFound} path="**" />
        <Redirect to="**" />
      </Switch>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
