import React from 'react'

import { Helmet } from 'react-helmet'

import './homescreen.css'

const Homescreen = (props) => {
  return (
    <div className="homescreen-container">
      <Helmet>
        <title>TUNNEL UI</title>
      </Helmet>
      <div className="homescreen-homescreen">
        <img
          src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/d5c9830b-4cfc-49cf-811a-6ebe0f8c0205/681bd4a4-df8f-439b-ba6e-fca1874a6549?org_if_sml=1256550"
          alt="Bg219022"
          className="homescreen-bg21"
        />
        <button className="homescreen-buttonsbutton">
          <span className="homescreen-text BodyRegular">
            <span>Button</span>
          </span>
        </button>
        <div className="homescreen-textblock">
          <span className="homescreen-text02 H1">
            <span>Welcome to Tunnel.</span>
          </span>
          <span className="homescreen-text04 H2">
            <span>
              <span>Luxury experiences,</span>
              <br></br>
              <span>accessible.</span>
            </span>
          </span>
        </div>
        <div className="homescreen-block">
          <div className="homescreen-line">
            <span className="homescreen-text09 H2">
              <span>Good evening, Martin</span>
            </span>
            <span className="homescreen-text11 H2">
              <span>0 points</span>
            </span>
          </div>
          <div className={'homescreen-divider'}></div>
        </div>
        <div className="homescreen-tabbar">
          <div className="homescreen-block1">
            <img
              src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/d5c9830b-4cfc-49cf-811a-6ebe0f8c0205/b1b69410-511d-4d9f-a154-c6d9aec2cd70?org_if_sml=1173"
              alt="Indicator9022"
              className="homescreen-indicator"
            />
            <img
              src="/external/vector9022-agno.svg"
              alt="Vector9022"
              className="homescreen-vector"
            />
            <span className="homescreen-text13 Tabbar">
              <span>HOME</span>
            </span>
          </div>
          <div className="homescreen-block2">
            <img
              src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/d5c9830b-4cfc-49cf-811a-6ebe0f8c0205/47e303ab-2fc7-447e-ad29-601213ec0745?org_if_sml=1123"
              alt="Indicator9022"
              className="homescreen-indicator1"
            />
            <img
              src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/d5c9830b-4cfc-49cf-811a-6ebe0f8c0205/595dc75b-2b90-4bc3-b59a-256a746cef29?org_if_sml=1153"
              alt="Rectangle38039022"
              className="homescreen-rectangle3803"
            />
            <span className="homescreen-text15 Tabbar">
              <span>HOTELS</span>
            </span>
          </div>
          <div className="homescreen-block3">
            <img
              src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/d5c9830b-4cfc-49cf-811a-6ebe0f8c0205/327bfe50-7226-459a-bd44-c93391cca586?org_if_sml=1123"
              alt="Indicator9022"
              className="homescreen-indicator2"
            />
            <img
              src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/d5c9830b-4cfc-49cf-811a-6ebe0f8c0205/fd1fa67a-54b2-444b-9f7a-8dcec4270385?org_if_sml=1153"
              alt="Rectangle38039022"
              className="homescreen-rectangle38031"
            />
            <span className="homescreen-text17 Tabbar">
              <span>FLIGHTS</span>
            </span>
          </div>
          <div className="homescreen-block4">
            <img
              src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/d5c9830b-4cfc-49cf-811a-6ebe0f8c0205/7d17eb26-d410-4b13-81f7-15cbe6ae9e1f?org_if_sml=1123"
              alt="Indicator9022"
              className="homescreen-indicator3"
            />
            <img
              src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/d5c9830b-4cfc-49cf-811a-6ebe0f8c0205/790ada6f-8b29-472c-8874-8481fb9fae4d?org_if_sml=1153"
              alt="Rectangle38039022"
              className="homescreen-rectangle38032"
            />
            <span className="homescreen-text19 Tabbar">
              <span>ACCOUNT</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Homescreen
